import React from "react"

const USP = () => {
  return (
    <section>
      <div className="fullWidth bg-blue-400 py-16 px-2 md:px-0 hero">
        <h2 className="text-3xl text-center font-semibold text-white pb-16">
          Our Difference
        </h2>
        <div className="container">
          <div className="md:w-full md:mb-8 lg:mr-8">
            <div className="md:flex">
              <div className="md:w-1/2 lg:max-w-1/4 rounded bg-white overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">
                    Highly Experienced
                  </div>
                  <p className="text-gray-600 text-base">
                    Klein Plumbing Services has over three decades of experience
                    in residential plumbing, so we get the job done right the
                    first time.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 rounded bg-white overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">
                    Licensed & Insured
                  </div>
                  <p className="text-gray-600 text-base">
                    Have peace of mind in knowing that we're extremely qualified
                    to be handling your repair. Everyone at our company must be
                    licensed and insured to be working on your home.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 bg-white rounded overflow-hidden shadow-lg mb-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Reliable</div>
                  <p className="text-gray-600 text-base">
                    We're dependable and consistent - we follow through on our
                    word. As experts at what we do, you can trust our advice.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-full">
            <div className="md:flex">
              <div className="md:w-1/2 lg:max-w-1/4 bg-white rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Prompt</div>
                  <p className="text-gray-600 text-base">
                    We understand that you need your issue fixed now, which is
                    why we show up when we say we will to repair your problem.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 bg-white rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Clean</div>
                  <p className="text-gray-600 text-base">
                    Our goal is to leave your home cleaner than the way we found
                    it. Plumbing is inherently messy, but we strive to make it
                    better, not worse.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 bg-white rounded overflow-hidden shadow-lg mb-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Detail-Oriented</div>
                  <p className="text-gray-600 text-base">
                    We pay careful attention to the intracacies of our craft so
                    that our quality holds up and your repair is solid for years
                    to come.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default USP
