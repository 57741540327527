import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import USP from "../../components/usp"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function TomballPlumber() {
  const data = useStaticQuery(graphql`
    query tomballImages {
      tomballPlumber: file(relativePath: { eq: "tomball-plumber.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Tomball Plumbing Company"
        description={`Our company has been providing plumbing services for the Tomball community for over a decade. We offer all kinds of residential plumbing repairs. `}
      />
      <HeroSectionAlt
        h1={`Tomball Plumbing`}
        h2={`Your Local Tomball Plumber`}
        h3={`Our company has kept the homes of local Tomball residents running smoothly for nearly two decades.     Our team is fully licensed and insured - have peace of mind
        knowing that we’ll fix your plumbing problem right the first
        time. 
        `}
        p1={`Our services in the Tomball area include repairing
        broken or corroded pipes, drains, leaks, water heaters,
        toilets, and sewer lines. Don’t hesitate to reach out and talk with our master plumber about how we can best serve you.
        `}
        heroImg={data.tomballPlumber.childImageSharp.fluid}
        heroImgAltTag={`Tomball Plumbing Company`}
      />
      <section>
        <div className="pb-16 px-2 md:px-0">
          <h2 className="font-bold leading-8 text-2xl mb-4">
            About Klein Plumbing Services
          </h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="md:w-full lg:mr-8">
              <div className="md:flex">
                <p className="text-gray-600 leading-normal text-lg mb-4"></p>
              </div>
              <div>
                <p className="text-gray-600 text-lg mb-4">
                  Klein Plumbing Services was founded by Sid Bruton in 2006 in
                  order to provide vastly superior - yet affordable - plumbing,
                  sewer, and water heater repair to the Tomball area.
                </p>
                <p className="text-gray-600 text-lg mb-4">
                  Family owned and operated, Klein Plumbing Services has had the
                  pleasure of serving Tomball residents with quality service and
                  plumbing repairs. Our professional advice is always
                  transparent, meaning we only recommend the necessary or
                  repairs and services you really need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <USP />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <CTA
        title={`Get a Free Quote Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default TomballPlumber
